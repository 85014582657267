<template>
  <!--ako se stavi absolute onda ce listovi da prelaze preko footera-->
  <v-footer padless height="auto">
    <v-card class="flex" color="#8d81b8">
      <v-card-text class="py-2 caption white--text text-center">
        2021 -
        <strong class="white--text">
          Developed by Igor Vasić
          <br />e-mail: vasicigorjsp@gmail.com
        </strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>


<script>
export default {};
</script>

<style>
</style>